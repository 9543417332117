<template>
  <el-card class="msg-list">
    <el-container style="height: auto; border: 1px solid #eee">
      <el-aside width="180px" style="background-color: rgb(238, 241, 246);overflow: hidden">
        <el-menu default-active="1-1" @select="onOptionsItemSelected">
          <div class="msg-c">
            消息中心
          </div>
          <el-submenu index="1">
            <template slot="title"><i class="el-icon-menu"></i>站内消息</template>
            <el-menu-item-group>
              <el-menu-item index="1-1">全部消息<span class="msgcount">{{ counter.totalCount }}</span>
              </el-menu-item>
              <el-menu-item index="1-2">未读消息<span class="msgcount">{{ counter.unReadCount }}</span>
              </el-menu-item>
              <el-menu-item index="1-3">已读消息<span class="msgcount">{{ counter.readCount }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header
          style="border-bottom: 1px solid #e1dde3;margin:3px 10px;padding: 0px;height: 88px">
          <div class="header-title">
            <div class="h-b"></div>
            <div class="h-title">{{ title }}</div>
          </div>
          <div>
            <el-row :gutter="10">
              <el-col :span="4">
                <div @click="SelectScach(null,0)" class="msg-btn"
                     :class="{active:selectIndex === 0}">
                  <a>全部消息</a>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="SelectScach('SYSTEM_NOTICE',1)" class="msg-btn"
                     :class="{active:selectIndex === 1}"><a>系统消息</a>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="SelectScach('NEW_ORDER',2)" class="msg-btn"
                     :class="{active:selectIndex === 2}"><a>新订单通知</a>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="SelectScach('DELISTING_NOTICE',3)" class="msg-btn"
                     :class="{active:selectIndex === 3}"><a>下架通知</a>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="SelectScach('TRANSACTION_ARRIVAL_NOTIFICATION',4)" class="msg-btn"
                     :class="{active:selectIndex === 4}"><a>到账通知</a>
                </div>
              </el-col>
              <el-col :span="4">
                <div @click="SelectScach('ARRIVAL_NOTICE',5)" class="msg-btn"
                     :class="{active:selectIndex === 5}"><a>到货通知</a>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-header>

        <el-main style="padding: 0px 20px;height: 550px;position: relative;">
          <div style="height: 100%;width: 50%;border-right: 1px solid #e1dde3;float: left;">
            <div>
              <el-table highlight-current-row
                        v-loading="loading"
                        ref="multipleTable"
                        :data="tableData"
                        tooltip-effect="dark"
                        style="width: 100%;height: 460px;overflow: auto;border-bottom: 0px"
                        @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="45">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="类型"
                  width="100">
                  <template slot-scope="scope">
                    <div style="overflow: hidden;width: 100px;height: 20px;">
                      {{ scope.row.messageType | eTypeFilter }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="标题内容"
                  width="150">
                  <template slot-scope="scope">
                    <div class="point" v-if="scope.row.status === 1"></div>
                    <a>
                      <div @click="showDetails(scope.row)"
                           style="overflow: hidden;width: 120px;height: 20px;">{{ scope.row.title }}
                      </div>
                    </a>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createDate"
                  align="center"
                  label="时间"
                  width="150">
                  <template slot-scope="scope">
                    {{ dateToString(scope.row.createDate) }}
                  </template>
                </el-table-column>
              </el-table>
              <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
                <el-pagination
                  :current-page="pageConfig.page"
                  :page-size="pageConfig.limit"
                  @size-change="pageConfig.handleSizeChange"
                  @current-change="pageConfig.handleCurrentChange"
                  :page-sizes="pageConfig.pageSizes"
                  :pager-count="5"
                  layout="prev, pager, next, total, sizes"
                  :total="pageConfig.total">
                </el-pagination>
              </div>
            </div>
            <div style="position: absolute;bottom: 5px" class="msg-btns">
              <el-button type="danger" @click="deleteMsg">删除</el-button>
              <el-button type="primary" @click="MarkReadMsg">标记已读</el-button>
              <el-button type="warning" @click="MarkAllReadMsg">全部已读</el-button>
            </div>
          </div>
          <div
            style="width: 50%;float: right;height: 550px;padding: 10px 0px 10px 10px;overflow: auto">
            <div class="msg-title">{{ Details.title }}</div>
            <div class="msg-time">{{ dateToString(Details.createdate) }}</div>
            <div class="msg-content rich-text" v-html="Details.content"></div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </el-card>
</template>

<script>
import {message} from '@/apis/index';

const messageType = [
  {name: '系统消息', type: 1},
  {name: '新订单通知', type: 2},
  {name: '审核通知', type: 3},
  {name: '到货通知', type: 4},
  {name: '下架通知', type: 5},
  {name: '强制下架', type: 6},
  {name: '退款通知', type: 7},
  {name: '新评价通知', type: 8},
  {name: '评价回复通知', type: 9},
  {name: '钱包到账通知', type: 10},
  {name: '留言通知', type: 11},
  {name: '交易通知', type: 12},
]

const config = require('../../resources/js/config');
export default {
  name: "messagelist",
  components: {
    GlobalHeader: () => import('@CMP/common/GlobalHeader'),
    SearchBar: () => import('@PAGE/home/HomeSearchBar'),
    GlobalFooter: () => import('@CMP/common/GlobalFooter')
  },
  data() {
    return {
      loading: true,
      show: true,
      title: "全部消息",
      ids: [],
      counter: {
        totalCount: 0,
        readCount: 0,
        unReadCount: 0,
      },
      selectIndex: 0,
      Details: {
        title: "",
        content: "",
        createdate: ""
      },
      selectScach: null,
      message: {
        status: null,
      },
      pageConfig: {
        page: 1,
        limit: 10,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        handleSizeChange: (limit) => {
          this.pageConfig.limit = limit;
          this.queryMessage()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.queryMessage()
        }
      },
      tableData: [],
      multipleSelection: []

    }
  }, filters: {
    eTypeFilter(type) {
      // 在过滤 器当中不能引用当前实例 this   this.payTypeOptions 错误的
      const payObj = messageType.find(obj => obj.type === type)
      // 非空返回类型名称
      return payObj ? payObj.name : null
    }
  }, methods: {
    handleSelectionChange(selectedArray) {
      this.ids = selectedArray.map(item => item.messageId);
    },
    onOptionsItemSelected(key, keyPath) {
      if (key === '1-1') {
        this.title = "全部消息"
        this.message.status = null
        this.queryMessage();
      } else if (key === '1-2') {
        this.title = "未读消息"
        this.message.status = 1
        this.queryMessage();
      } else {
        this.title = "已读消息"
        this.message.status = 0
        this.queryMessage();
      }
    },
    queryMessage() {
      this.loading = true;
      message.listMessage({
        page: this.pageConfig.page,
        limit: this.pageConfig.limit,
        userId: this.user.userId,
        status: this.message.status,
        messageType: this.selectScach,
      }).then(res => {
        let {retdata, count, counter} = res;
        this.tableData = retdata;
        this.pageConfig.total = count;
        Object.keys(counter).forEach(key => {
          this.counter[key] = counter[key];
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    showDetails(Details) {
      this.Details.title = Details.title;
      this.Details.createdate = Details.createDate;
      this.Details.content = Details.content;
      if (Details.status === 1) {
        this.ids = [];
        this.ids.push(Details.messageId);
        this.setMarkread(1);
      }
    },
    setMarkread(type) {
      this.$request.post({
        reqcode: '1201',
        reqdesc: '标记已读',
        ids: this.ids,
        userid: this.user.userId,
        type: type
      })
    },
    setDelete() {
      this.$request.post({
        reqcode: '1202',
        reqdesc: '批量删除消息',
        ids: this.ids,
      }).then(res => {
        this.$message.success("删除成功");
        this.queryMessage();
      });
    },
    deleteMsg() {
      if (this.ids.length === 0) {
        this.$message.warning('请至少选择一条数据')
      } else {
        this.$confirm('确定要执行该操作嘛', '提示', {}).then(() => {
          this.setDelete();
          this.queryMessage();
        })
      }
    },
    MarkReadMsg() {
      if (this.ids.length === 0) {
        this.$message.warning('请至少选择一条数据')
      } else {
        this.$confirm('确定要执行该操作嘛', '提示', {}).then(() => {
          this.setMarkread(1);
          this.$message.success("标记已读成功");
          this.queryMessage();
        })
      }
    },
    MarkAllReadMsg() {
      this.$confirm('确定要执行该操作嘛', '提示', {}).then(() => {
        this.setMarkread(2);
        this.$message.success("标记全部已读成功");
        this.queryMessage();
      })
    },
    SelectScach(messageType, index) {
      this.selectIndex = index;
      this.selectScach = messageType;
      this.queryMessage();
    }
  },
  computed: {},
  beforeMount() {
    this.queryMessage();
  }
}
</script>

<style scoped lang="scss">
.msg-list {
  width: 1190px;
  margin: 15px auto 7px;
}

.msg-c {
  padding: 33px 0px;
  text-align: center;
  font-size: 17px;
  border-bottom: 1px solid #e1dde3;
}

.h-title {
  font-size: 16px;
  color: #a19d9d;
  line-height: 17px;
  margin-left: 10px;
}

.h-b {
  width: 2px;
  height: 16px;
  background-color: orange;
}

.msg-list .msg-btn {
  background-color: #ffd1a7;
  height: 38px;
  color: #FF8746;
  text-align: center;
  font-size: 12px;
  line-height: 38px;
  margin-top: 7px;
}

.point {
  width: 5px;
  height: 5px;
  border-radius: 9px;
  background-color: #ff9d4f;
  position: absolute;
  left: 0px;
  top: 23px;
}

.header-title {
  display: flex;
  margin-top: 8px;
  height: 25px;
  border-bottom: 1px solid #d8d6da;
}

.msg-list ::v-deep .el-table__body {
  font-size: 12px;
  width: 100% !important;
}

.msg-list ::v-deep .el-table__body tr.current-row > td {
  background-color: #ffd1a7 !important;
  color: #fff;
}

.msg-list .msg-btns button {
  height: 30px;
  font-size: 13px;
  line-height: 8px;
}

.active {
  color: white !important;
}

.msg-list .msg-title {
  text-align: center;
  font-size: 14px;
}

.msg-list .msg-time {
  text-align: center;
  margin: 7px 0px;
  height: 22px;
  border-bottom: 1px solid #e1dde3;
}

.msg-content {
  margin-top: 7px;
}

.msg-list .msgcount {
  font-size: 12px;
  margin-left: 5%;
  padding: 3px 5px;
  width: auto;
  height: 25px;
  background-color: #FF8746;
  color: white;
}

.msg-list ::v-deep .el-card__body {
  padding: 10px;
}

.msg-list ::v-deep .el-table::before {
  height: 0px;
}

</style>
